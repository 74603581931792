import React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import Content from "src/components/pages/use-cases/Content";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import Patrons from "src/components/layout/Patrons/Patrons";

export default function index() {
  return (
    <Layout>
      <Seo />
      <Content />
      <ContactFooter showForm={true} showInfo={true} />
      <Patrons />
    </Layout>
  );
}
