import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import * as styles from "./Content.module.scss";
import ReactMarkdown from "react-markdown";
import Image from "src/assets/use-cases/First.svg";
import RetailImg from "src/assets/use-cases/retail.svg";
import FieldImg from "src/assets/use-cases/field.svg";
import { gsap } from 'gsap'

const industryText = ["Kiedy firmowa komunikacja góra-dół odbywa się werbalnie lub przy pomocy tablicy ogłoszeń, **informacje wymieniane między pracownikami zniekształcają się lub całkiem giną.** Wpływa to na każdy szczebel organizacji:", "**Pracownicy fizyczni** są sfrustrowani, bo nie mają łatwego dostępu do ważnych dla siebie informacji. **Pracownicy biurowi** bez przerwy odpowiadają na te same pytania i weryfikują, czy informacje dotarły tam, gdzie miały dojść. **Zarząd** traci kontrolę nad firmą. Panuje chaos informacyjny, ale można nad nim zapanować!", "Aplikacja mobilna Hexar zapewnia łatwy dostęp do ważnych danych wszystkim pracownikom firmy i odciąża zespół HR od czasochłonnych, powtarzalnych zadań. Zarząd i managerowie odzyskują kontrolę nad linią produkcyjną i łatwiej delegują zadania i kontrolują ich wykonanie."]
  ;

const retailText = ["Gdy **centrala firmy deleguje zadania do wielu oddziałów**, a manager każdej lokalizacji w inny sposób raportuje wykonanie zadania, dochodzi do zakłóceń w wymianie informacji. Często wynika to z faktu, że pracownicy sklepów korzystają z prywatnych telefonów, aby zrobić zdjęcie wykonanego zadania, następnie wysyłają je z prywatnego maila na służbowy i dopiero na końcu przesyłają potwierdzenie do biura.", "Pozornie proste zadanie wydłuża się, a dostarczane raporty są niejednolite i niespójne.", "Ale nie z Hexar! Narzędzie przyspiesza zlecanie i raportowanie zadań oraz zwiększa kontrolę nad procesem wymiany informacji między centralą i sklepami.", "Dzięki aplikacji, **biuro** utrzymuje całą komunikację dotyczącą zleceń w jednym miejscu i łatwo sprawdza status wykonanych zleceń. **Pracownicy sklepów** korzystają tylko z jednej aplikacji do odbierania i raportowania zadań. Bez zakłóceń i szumów."];

const fieldTeamText = ["Hexar sprawdzi się w każdej firmie, w której **rozproszony zespół terenowy wykonuje zadania w różnych lokalizacjach.** Zawodowi kierowcy, agenci ubezpieczeniowi, pracownicy budowlani, czy przedstawiciele handlowi - wszyscy pracują z dala od biura i muszą być z nim w stałym kontakcie.", "Hexar ułatwia i przyspiesza wymianę informacji między **siedzibą firmy, a pracownikami w terenie.** Pozwala szybko zlecać zadania do konkretnych jednostek i otrzymywać informację zwrotną na temat postępu prac lub wykonanego zadania.", "Dzięki aplikacji znika problem rozproszenia informacji i niedomówień dotyczących zleceń. Wszyscy pracownicy firmy mają bezpośredni dostęp do tych samych informacji i do siebie nawzajem - z poziomu jednego, nowoczesnego, mobilnego narzędzia."];


const content = [
  { id: "section_1", title: "Przemysł", text: industryText, Image: Image },
  { id: "section_2", title: "Retail", text: retailText, Image: RetailImg },
  { id: "section_3", title: "Zespół w terenie", text: fieldTeamText, Image: FieldImg }]

const Content = () => {
  const text1Ref = useRef(null)
  const text2Ref = useRef(null)
  const text3Ref = useRef(null)

  const picture1Ref = useRef(null)
  const picture2Ref = useRef(null)
  const picture3Ref = useRef(null)

  let text1Animation: any;
  let text2Animation: any;
  let text3Animation: any;

  let picture1Animation: any;
  let picture2Animation: any;
  let picture3Animation: any;

  const commonAnimation = {
    y: 100,
    opacity: 0,
    duration: 1,
    ease: "power2.out",
  };

  useEffect(() => {
    picture1Animation = gsap
      .timeline({
        scrollTrigger: {
          trigger: picture1Ref.current,
          start: "top 70%",
          toggleActions: "play none none none "
        },
      })
      .from(picture1Ref.current, commonAnimation)
    picture2Animation = gsap
      .timeline({
        scrollTrigger: {
          trigger: picture2Ref.current,
          start: "top 70%",
          toggleActions: "restart pause resume reverse"
        },
      })
      .from(picture2Ref.current, commonAnimation)
    picture3Animation = gsap
      .timeline({
        scrollTrigger: {
          trigger: picture3Ref.current,
          start: "top 70%",
          toggleActions: "restart pause resume reverse"
        },
      })
      .from(picture3Ref.current, commonAnimation)
    text1Animation = gsap
      .timeline({
        scrollTrigger: {
          trigger: text1Ref.current,
          start: "top 70%",
          toggleActions: "play none none none"
        },
      })
      .from(text1Ref.current, commonAnimation)
    text2Animation = gsap
      .timeline({
        scrollTrigger: {
          trigger: text2Ref.current,
          start: "top 70%",
          toggleActions: "restart pause resume reverse"
        },
      })
      .from(text2Ref.current, commonAnimation)
    text3Animation = gsap
      .timeline({
        scrollTrigger: {
          trigger: text3Ref.current,
          start: "top 70%",
          toggleActions: "restart pause resume reverse"
        },
      })
      .from(text3Ref.current, commonAnimation)

    return () => {
      picture1Animation.kill();
      picture2Animation.kill();
      picture3Animation.kill();

      text1Animation.kill();
      text2Animation.kill();
      text3Animation.kill();
    }
  }, [])
  return (
    <section className={styles.SectionWrapper}>

      <div className={styles.ArticlesWrapper} >
        {content.map(({ id, text, title, Image }, i) => (
          <article className={clsx(styles.Article)} key={`article_${id}`} >
            <div className={styles.ImageWrapper} ref={i == 0 ? picture1Ref : i == 1 ? picture2Ref : i == 2 ? picture3Ref : picture1Ref}>
              <Image />
            </div>
            <div className={styles.TextWrapper} ref={i == 0 ? text1Ref : i == 1 ? text2Ref : i == 2 ? text3Ref : text1Ref}>
              <h2>{title}</h2>

              {text.map(paragraph => <ReactMarkdown>{paragraph}</ReactMarkdown>)}


            </div>
          </article>
        ))}
      </div>
    </section >
  );
}

export default Content;