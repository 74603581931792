// extracted by mini-css-extract-plugin
export var MaxWidthContainer = "Content-module--MaxWidthContainer--yo7YT";
export var CommonPaddings = "Content-module--CommonPaddings--Yn2vZ";
export var SectionWrapper = "Content-module--SectionWrapper--IudYc";
export var SectionNavigation = "Content-module--SectionNavigation--6tTfA";
export var CustomParagraph = "Content-module--CustomParagraph--U8LJo";
export var NavigationContentWrapper = "Content-module--NavigationContentWrapper--3IglI";
export var ButtonContent = "Content-module--ButtonContent--Nvnn4";
export var ButtonContentActive = "Content-module--ButtonContentActive--iM+Ht";
export var ProgressBar = "Content-module--ProgressBar--l0gA+";
export var ArticlesWrapper = "Content-module--ArticlesWrapper--506GA";
export var Article = "Content-module--Article--ppRZQ";
export var TextWrapper = "Content-module--TextWrapper---Y+QX";
export var ImageWrapper = "Content-module--ImageWrapper--gi2M6";